import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"

// Components
import { Link, graphql } from "gatsby"

const Tags = (props) => {
  const { pageContext, data } = props
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} Post${
    totalCount === 1 ? "" : "s"
  } Tagged with "${tag}"`
  const siteTitle = data.site.siteMetadata.title
  const siteSlogan = data.site.siteMetadata.slogan
  let str = props.location.pathname.slice(1)
  const frontmatter = {
    slug: str.slice(0, -1)
  };

  return (
    <Layout title={siteTitle} slogan={siteSlogan}>
      <SEO 
        title={tagHeader}
        frontmatter={frontmatter}
      />
      <main>
        <h1 className="blog-heading">{tagHeader}</h1>
        <div>
          <ul>
            {edges.map(({ node }) => {
              const { slug } = node.fields
              const { title } = node.frontmatter
              return (
                <li key={slug}>
                  <Link to={slug}>{title}</Link>
                </li>
              )
            })}
          </ul>
          {/*
                  This links to a page that does not yet exist.
                  We'll come back to it!
                */}
          <Link to="/tags">All tags</Link>
        </div>
        <div className="spacer300"></div>
      </main>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        slogan
      }
    }
  }
`